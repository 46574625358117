import React from 'react';

function Footer() {
  
  return (
    <p style={{textAlign:"center"}}>
        <a href="https://motasimfoad.com" target="_blank" rel="noopener noreferrer">
        (C) Motasim Foad, v2.0
        </a>
    </p>
  );
}

export default Footer;
